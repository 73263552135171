import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { selectUserToken } from "../../features/user";
import usuarioApi from "../../utility/api/usuarioApi";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { NotAllowed } from "../NotAllowed";
import useAccessProfile from "../../hooks/useAccessProfile";

export const ConsultaUsuarios = () => {
  const token = useSelector(selectUserToken);

  const { isManager } = useAccessProfile();

  const colunas = [
    {
      label: "Usuário",
      prop: "userName",
      width: "23%",
    },
    {
      label: "Login",
      prop: "login",
      width: "23%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: usuarioApi.listarUsuarios,
      },
      onExcluir: {
        fetchFunction: usuarioApi.excluirUsuario,
        questionText: "Realmente deseja excluir este usuário?",
        successText: "Usuário excluído com sucesso!",
      },
      editarLink: (item) => `/usuario/editar?userId=${item.id}`,
      showExclur: false,
    };
  }, []);

  const filtros = [
    {
      label: "Nome",
      placeholder: "Pesquise pelo nome do usuário",
      name: "queryNome",
    },
  ];

  const consulta = useConsulta(token, events);

  if (!isManager) return <NotAllowed />;

  return (
    <div className="ml-10">
      <Consulta
        title="Usuários"
        adicionarLink={"/usuario/cadastro"}
        showOptions={true}
        colunas={colunas}
        consulta={consulta}
        hasStatus={false}
        filtros={filtros}
      />
    </div>
  );
};
