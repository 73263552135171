import informacaoNaoCadastradaImg from "../../assets/imagens/informacao-nao-cadastrada.svg";

const InformacaoNaoCadastrada = () => {
  return (
    <div className="w-full mt-10 flex justify-center">
      <img className="max-w-[60%]" alt="Informação não cadastrada" src={informacaoNaoCadastradaImg} />
    </div>
  );
};

export default InformacaoNaoCadastrada;
