import * as Tabs from "@radix-ui/react-tabs";
import Dropdown from "../../../components/Dropdown";

export const MatrizTab = ({
  employees,
  register,
  setValue,
  getValues,
  watch,
  errors,
}) => {
  
  return (
    <Tabs.Content value="matriz" className="w-full !bg-transparent">
      <div className="flex bg-white rounded-md border border-[#ECECEC] rounded-tl-none flex-col p-4">
        <div className="my-3">
          <h5 className="text-[#8A92A6]">Escolha um Funcionário da Matriz de Habilidades</h5>
        </div>
        <div>
          <div>
            <Dropdown
              defaultText="Selecione um Funcionário"
              options={[...employees]}
              name="employeeId"
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              label="Funcionário"
              required={false}
              errors={errors}
              multiple
            />
          </div>
        </div>
      </div>
    </Tabs.Content>
  );
};
