import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Input from "../../components/Input";
import PerfilAcessoCollapse from "./PerfilAcessoCollapse";
import CancelarButton from "../../components/CancelarButton";
import useQuery from "../../utility/useQuery";
import moduloApi from "../../utility/api/moduloApi";
import { selectUserToken } from "../../features/user";
import { NotAllowed } from "../../hooks/useAthorize";
import useAccessProfile from "../../hooks/useAccessProfile";
import { useEffect } from "react";
import { useState } from "react";
import useRequest, { Status } from "../../hooks/useRequest";
import Loading from "../../components/Loading";
import perfilAcessoApi from "../../utility/api/perfilAcessoApi";
import { Navigate } from "react-router-dom";

const EdicaoPerfilAcesso = () => {
  const token = useSelector(selectUserToken);
  const navigate = useNavigate();
  const {
    handleSubmit,
    watch,
    getValues,
    reset,
    register,
    formState: { errors },
  } = useForm();
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState();
  const [updatedModules, setUpdatedModules] = useState([]);
  const [profile, setProfile] = useState();

  let profileId = query.get("perfilacessoId");

  const { isManager } = useAccessProfile();

  const modulesRequest = useRequest(() => moduloApi.listarModulos({}, token));
  const profileRequest = useRequest(() =>
    perfilAcessoApi.buscarPerfilAcesso(profileId, token)
  );
  const editProfileRequest = useRequest((data) =>
    perfilAcessoApi.atualizarPerfilAcesso(data, token)
  );

  useEffect(() => {
    modulesRequest.execute();
    profileRequest.execute();
  }, []);

  useEffect(() => {
    if (modulesRequest.data) {
      setModules(modulesRequest.data);
    }

    if (profileRequest.data) {
      const profile = profileRequest.data;
      setProfile(profile);
      reset({
        name: profile.nome,
      });
    }
  }, [modulesRequest.data, profileRequest.data]);

  useEffect(() => {
    setLoading(
      modulesRequest.status === Status.Loading ||
        profileRequest.status === Status.Loading ||
        editProfileRequest.status === Status.Loading
    );

    if (editProfileRequest.status === Status.Success) {
      resetForm();
      Swal.fire({
        title: "Sucesso!",
        text: "Atualizado com sucesso",
        icon: "success",
      });
      navigate("/perfilacesso/listagem");
    }

    if (editProfileRequest.status === Status.Failed) {
      Swal.fire({
        title: "Erro!",
        text: "Algo deu errado",
        icon: "error",
      });
    }
  }, [modulesRequest.status, profileRequest.status, editProfileRequest.status]);

  const onSubmit = (data) => {
    editProfileRequest.execute({
      ...data,
      id: profileId,
      modules: updatedModules,
    });
  };

  const resetForm = useCallback(() => {
    Swal.fire({
      text: "Realmente deseja cancelar a edição?",
      showDenyButton: true,
      confirmButtonText: "Sim",
      denyButtonText: `Não`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        reset();

        Swal.fire({
          text: "Cancelado com sucesso!",
          icon: "success",
        });
      }
    });
  }, [reset]);

  if (!isManager) return <NotAllowed />;

  return (
    <>
      {loading && <Loading isVisible={true} />}
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <div className="w-full min-h-screen flex flex-col pb-[60px]">
          <div className="flex flex-col justify-center pt-[100px] mx-[15%]">
            <div className="mb-4 text-[24px] font-semibold text-[#1B2646]">
              Edição de Perfil de Acesso
            </div>
            <div>
              <label>Descrição do Perfil de Acesso</label>
              <Input
                name="name"
                register={register}
                errors={errors}
                required={true}
                maxLength="30"
              />
            </div>
          </div>

          <div className="flex-grow bg-white rounded-md border border-[#ECECEC] py-4 px-11 mt-6 mx-[15%]">
            {modules && profile && (
              <PerfilAcessoCollapse
                modulos={modules}
                modulosDoPerfil={profile.modulos}
                onCheck={(updMods) => setUpdatedModules(updMods)}
              />
            )}
          </div>

          <div className="flex justify-center mt-6">
            <div className="w-[70%] undefined bg-white rounded-md border border-[#ECECEC] py-4 flex items-center justify-between px-11">
              <CancelarButton
                voltar={() => navigate("/perfilacesso/listagem")}
                watch={watch}
                getValues={getValues}
                reset={resetForm}
                wide
              />
              <button className="w-[181px] h-[44px] bg-[#187733] text-white rounded-[4px]">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EdicaoPerfilAcesso;
