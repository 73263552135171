import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { selectUserToken } from "../../features/user";
import departamentoApi from "../../utility/api/departamentoApi";
import useQuery from "../../utility/useQuery";
import CadastroDepartamento from "./CadastroDepartamento";
import EdicaoDepartamento from "./EdicaoDepartamento";
import useAccessProfile from "../../hooks/useAccessProfile";
import { NotAllowed } from "../NotAllowed";

const ConsultaDepartamentos = () => {
  const token = useSelector(selectUserToken);

  const [isCadastroVisible, setIsCadastroVisible] = useState(false);
  const [isEdicaoVisible, setIsEdicaoVisible] = useState(false);

  const { isManager } = useAccessProfile();

  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  let isCadastro = location.pathname === "/departamento/cadastro";
  let pagina = parseInt(query.get("page") ?? 0);

  useEffect(() => {
    if (isCadastro) setIsCadastroVisible(true);
  }, [isCadastro]);

  useEffect(() => {
    if (!isCadastroVisible) navigate("/departamento/listagem");
  }, [isCadastroVisible, navigate]);

  const filtros = [
    {
      label: "Descrição",
      placeholder: "Pesquise pelo descrição do departamento",
      name: "queryNome",
    },
  ];

  const customSCIColumnContent = (item) => (
    <span>{item.hasSCI ? "Sim" : "Não"}</span>
  );

  const colunas = useMemo(() => {
    return [
      {
        label: "Descrição",
        prop: "description",
        width: "35%",
      },
      {
        label: "Divisão",
        prop: "divisionName",
        width: "35%",
      },
      {
        label: "Tem SCI?",
        prop: "hasSCI",
        width: "30%",
        customContent: customSCIColumnContent,
      },
    ];
  }, []);

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: departamentoApi.listarDepartamentos,
      },
      onExcluir: {
        fetchFunction: departamentoApi.excluirDepartamento,
        questionText: "Realmente deseja excluir este departamento?",
        successText: "Departamento excluído com sucesso!",
      },
      showExcluir: (item) => item.isDeletable,
      editarLink: (item) => `/departamento/editar?departmentId=${item.id}`,
      editarEvent: () => setIsEdicaoVisible(true),
    };
  }, [pagina]);

  const consulta = useConsulta(token, events);

  let adicionarButton = (
    <>
      <button
        type="button"
        onClick={() => setIsCadastroVisible(true)}
        className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
      >
        Adicionar
      </button>
      {isCadastroVisible && (
        <CadastroDepartamento
          setIsVisible={setIsCadastroVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
      {isEdicaoVisible && (
        <EdicaoDepartamento
          setIsVisible={setIsEdicaoVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
    </>
  );

  if (!isManager) return <NotAllowed />;

  return (
    <div className="ml-10">
      <Consulta
        title="Departamentos"
        adicionarLink={"/departamento/cadastro"}
        showOptions={true}
        colunas={colunas}
        consulta={consulta}
        filtros={filtros}
      />
    </div>
  );
};

export default ConsultaDepartamentos;
