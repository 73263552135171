import React from "react";
import CardAction from "./CardAction";
import CardColuna from "./CardColuna";

const Card = ({
  item,
  colunas,
  index,
  search,
  reset,
  cardOptionsIndex,
  setCardOptionsIndex,
  events,
  showOptions,
}) => {
  return (
    <div className="w-full min-h-[80px] px-[2%] grid grid-cols-[1fr] t-f1:grid-cols-[minmax(88%,_1fr)_minmax(110px,_370px)] items-center justify-center mt-2 border bg-white rounded-lg">
      <div className="t-f1:flex">
        {colunas.map((coluna, index) => (
          <CardColuna key={index} configuration={coluna} item={item} />
        ))}
      </div>
      <div className="flex items-center grow justify-between">
        <div className="flex">
          {item.status && (
            <div className="flex flex-col">
              <div className="text-[13px] text-[#8A92A6]">Status</div>
              <div>
                <button
                  className={`${
                    item.status === "Ativo" ? "bg-[#187733]" : "bg-[#AF0011] "
                  } text-[10px] py-1 px-2 font-semibold text-white rounded-md w-[50px]`}
                >
                  {item.status}
                </button>
              </div>
            </div>
          )}
        </div>
        {showOptions && (
          <CardAction
            index={index}
            item={item}
            search={search}
            reset={reset}
            isAtivo={item.status === "Ativo"}
            isCardOptionsVisible={cardOptionsIndex === index}
            setCardOptionsIndex={setCardOptionsIndex}
            onAtivar={events.onAtivar}
            onInativar={events.onInativar}
            onExcluir={events.onExcluir}
            // showExcluir={events.showExcluir?.(item)}
            showExcluir={true}
            editarLink={events.editarLink?.(item)}
            editarEvent={events.editarEvent}
          />
        )}
      </div>
    </div>
  );
};

export default Card;
