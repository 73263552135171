import * as Tabs from "@radix-ui/react-tabs";
import Button from "../../../components/Button";
import buttonClasses from "../../../components/Button/typing";
import TempDropdown from "../../../components/Dropdown/TempDropdown";
import TrashIcon from "../../../assets/imagens/thrash.png";

export const PerfilAcessoTab = ({ divisionsProfiles, profiles, setisVisiblePerfil, removeDivision, setValue }) => {
  return (
    <Tabs.Content value="perfilAcesso" className="w-full !bg-transparent">
      <div className="flex bg-white rounded-md border border-[#ECECEC] rounded-tl-none flex-col p-4">
        <div className="my-10">
          <Button
            className="py-1 px-5"
            width=""
            height=""
            colorClass={buttonClasses.lightBlue}
            onClick={() => setisVisiblePerfil(true)}
          >
            Adicionar
          </Button>
        </div>
        <div>
          <div className="bg-[#1B2646] rounded-t-md px-8 py-1 flex">
            <div className="w-[40%]">
              <span>Divisão</span>
            </div>
            <div className="w-[70%]">
              <span>Perfis de Acesso</span>
            </div>
          </div>
          <div className="text-black">
            {divisionsProfiles &&
              divisionsProfiles.map((dp) => (
                <div
                  key={dp.divisionId}
                  className="flex border-x border-b last:rounded-b-md px-8 py-4 items-center"
                >
                  <div className="w-[40%] pr-4 whitespace-nowrap overflow-hidden text-ellipsis">
                    <span>{dp.divisionName || "Sem nome"}</span>
                  </div>
                  <div className="w-[60%]">
                    {profiles && (
                      <TempDropdown
                        placeholder="Selecione"
                        options={profiles}
                        value={dp.profiles.map((p) => ({
                          value: p.id,
                          label: p.name,
                        }))}
                        onChange={(newValue) => {
                          dp.profiles = newValue.map((p) => ({
                            id: p.value,
                            name: p.label,
                          }));
                          setValue("divisionProfiles", [...divisionsProfiles]);
                        }}
                      />
                    )}
                  </div>
                  <div className="w-[10%] flex justify-end">
                    <Button
                      width=""
                      height=""
                      className="p-3"
                      colorClass={buttonClasses.transparent}
                      onClick={() => removeDivision(dp.divisionId)}
                    >
                      <img alt="Remover Perfil de Acesso" src={TrashIcon} />
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Tabs.Content>
  );
};
