import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { selectUserToken } from "../../features/user";
import perfilAcessoApi from "../../utility/api/perfilAcessoApi";
import useQuery from "../../utility/useQuery";
import CadastroPerfilAcesso from "./CadastroPerfilAcesso";
import EdicaoPerfilAcesso from "./EdicaoPerfilAcesso";
import useAccessProfile from "../../hooks/useAccessProfile";
import { NotAllowed } from "../NotAllowed";

const ConsultaPerfilAcesso = () => {
  const token = useSelector(selectUserToken);

  const [isCadastroVisible, setIsCadastroVisible] = useState(false);
  const [isEdicaoVisible, setIsEdicaoVisible] = useState(false);

  const { isManager } = useAccessProfile();

  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  let isCadastro = location.pathname === "/perfilacesso/cadastro";
  let pagina = parseInt(query.get("page") ?? 0);

  useEffect(() => {
    if (isCadastro) setIsCadastroVisible(true);
  }, [isCadastro]);

  useEffect(() => {
    if (!isCadastroVisible) navigate("/perfilacesso/listagem");
  }, [isCadastroVisible, navigate]);

  const filtros = [
    {
      label: "Perfil de Acesso",
      placeholder: "Pesquise pelo nome...",
      name: "nome",
    },
  ];

  const colunas = [
    {
      label: "Nome",
      prop: "nome",
      width: "30%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: perfilAcessoApi.listarPerfisAcessos,
      },
      onAtivar: {
        fetchFunction: perfilAcessoApi.ativarPerfilAcesso,
        successText: "Perfil de Acesso ativado",
      },
      onInativar: {
        fetchFunction: perfilAcessoApi.inativarPerfilAcesso,
        successText: "Perfil de Acesso inativado",
      },
      onExcluir: {
        fetchFunction: perfilAcessoApi.excluirPerfilAcesso,
        questionText: "Realmente deseja excluir este perfil de acesso?",
        successText: "Perfil de Acesso excluído com sucesso!",
      },
      showExcluir: (item) => item.isDeletable,
      editarLink: (item) =>
        `/perfilacesso/edicao?page=${pagina}&perfilacessoId=${item.id}`,
      editarEvent: () => setIsEdicaoVisible(true),
    };
  }, [pagina]);

  const consulta = useConsulta(token, events);

  let adicionarButton = (
    <>
      <button
        type="button"
        onClick={() => navigate("/perfilacesso/cadastro")}
        className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
      >
        Adicionar
      </button>
      {isCadastroVisible && (
        <CadastroPerfilAcesso
          setIsVisible={setIsCadastroVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
      {isEdicaoVisible && (
        <EdicaoPerfilAcesso
          setIsVisible={setIsEdicaoVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
    </>
  );

  if (!isManager) return <NotAllowed />;

  return (
    <div className="ml-10">
      <Consulta
        title="Perfis de Acesso"
        adicionarButton={adicionarButton}
        showOptions={true}
        colunas={colunas}
        consulta={consulta}
        filtros={filtros}
      />
    </div>
  );
};

export default ConsultaPerfilAcesso;
