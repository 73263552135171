import { Routes, Route } from "react-router-dom";
import Layout from "./routes/Layout";
import Login from "./routes/Login";

import Home from "./routes/Home";

import RecuperacaoSenha from "./routes/Conta/RecuperacaoSenha";
import PrimeiroAcesso from "./routes/Conta/PrimeiroAcesso";
import RequisicaoRecuperacaoSenha from "./routes/Conta/RequisicaoRecuperacaoSenha";
import SenhaExpirada from "./routes/Conta/SenhaExpirada";

import ConsultaDepartamentos from "./routes/Departamentos/ConsultaDepartamentos";

import { ConsultaUsuarios } from "./routes/Usuarios/ConsultaUsuarios";
import { CadastroUsuario } from "./routes/Usuarios/CadastroUsuario";
import { EdicaoUsuario } from "./routes/Usuarios/EdicaoUsuario";

import CadastroDivisao from "./routes/Divisoes/CadastroDivisao";
import ConsultaDivisoes from "./routes/Divisoes/ConsultaDivisoes";
import EdicaoDivisao from "./routes/Divisoes/EdicaoDivisao";

import ConsultaModulo from "./routes/Modulos/ConsultaModulo";
import ConsultaPaises from "./routes/Paises/ConsultaPaises";
import ConsultaPerfilAcesso from "./routes/PerfilAcesso/ConsultaPerfilAcesso";
import ConsultaPermissao from "./routes/Permissoes/ConsultaPermissao";
import ConsultaEstados from "./routes/Estados/ConsultaEstados";
import ConsultaCidades from "./routes/Cidades/ConsultaCidades";
import Logout from "./routes/Logout";
import CarregarModulo from "./routes/Modulos/CarregarModulo";
import { checkInternetConnection } from "./utility/checkInternetConnection";
import { useEffect, Suspense } from "react";
import CadastroPerfilAcesso from "./routes/PerfilAcesso/CadastroPerfilAcesso";
import EdicaoPerfilAcesso from "./routes/PerfilAcesso/EdicaoPerfilAcesso";
import EdicaoDepartamento from "./routes/Departamentos/EdicaoDepartamento";
import CadastroDepartamento from "./routes/Departamentos/CadastroDepartamento";
import Auth2 from "./routes/Auth2/index";
import Loading from "./components/Loading";


function App() {
  window.onbeforeunload = function () {
    sessionStorage.hasShowError = "";
  };

  useEffect(() => {
    checkInternetConnection();

    const interval = setInterval(() => {
      checkInternetConnection();
    }, 15 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Suspense fallback={<Loading isVisible={true} />}>
      <Routes>
        <Route path="/modulo/carregar-modulo" element={<CarregarModulo />} />

        <Route path="/Conta">
          <Route path="/Conta/PrimeiroAcesso" element={<PrimeiroAcesso />} />
          <Route path="/Conta/Login" element={<Login />} />
          <Route path="/Conta/Auth2" element={<Auth2 />} />
          <Route path="/Conta/Logout" element={<Logout />} />
          <Route path="/Conta/SenhaExpirada" element={<SenhaExpirada />} />
          <Route path="/Conta/RecuperacaoSenha" element={<RecuperacaoSenha />} />
          <Route
            path="/Conta/RequisicaoRecuperacaoSenha"
            element={<RequisicaoRecuperacaoSenha />}
          />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route path="/usuario">
            <Route path="/usuario/listagem" element={<ConsultaUsuarios />} />
            <Route path="/usuario/cadastro" element={<CadastroUsuario />} />
            <Route
              path="/usuario/editar"
              element={<EdicaoUsuario hasEdicao={true} />}
            />
          </Route>

          <Route path="/departamento">
            <Route
              path="/departamento/listagem"
              element={<ConsultaDepartamentos />}
            />
            <Route
              path="/departamento/cadastro"
              element={<CadastroDepartamento />}
            />
            <Route
              path="/departamento/editar"
              element={<EdicaoDepartamento hasEdicao={true} />}
            />
          </Route>

          <Route path="/divisao">
            <Route path="/divisao/listagem" element={<ConsultaDivisoes />} />
            <Route path="/divisao/cadastro" element={<CadastroDivisao />} />
            <Route path="/divisao/editar" element={<EdicaoDivisao />} />
          </Route>

          <Route path="/modulo">
            <Route path="/modulo/listagem" element={<ConsultaModulo />} />
            <Route path="/modulo/cadastro" element={<ConsultaModulo />} />
          </Route>

          <Route path="/permissao">
            <Route path="/permissao/listagem" element={<ConsultaPermissao />} />
            <Route path="/permissao/cadastro" element={<ConsultaPermissao />} />
          </Route>

          <Route path="/perfilacesso">
            <Route
              path="/perfilacesso/listagem"
              element={<ConsultaPerfilAcesso />}
            />
            <Route
              path="/perfilacesso/cadastro"
              element={<CadastroPerfilAcesso />}
            />
            <Route path="/perfilacesso/edicao" element={<EdicaoPerfilAcesso />} />
          </Route>

          <Route path="/pais">
            <Route path="/pais/listagem" element={<ConsultaPaises />} />
            <Route path="/pais/cadastro" element={<ConsultaPaises />} />
          </Route>

          <Route path="/estado">
            <Route path="/estado/listagem" element={<ConsultaEstados />} />
            <Route path="/estado/cadastro" element={<ConsultaEstados />} />
          </Route>

          <Route path="/cidade">
            <Route path="/cidade/listagem" element={<ConsultaCidades />} />
            <Route path="/cidade/cadastro" element={<ConsultaCidades />} />
          </Route>

          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </Suspense>

  );
}

export default App;
