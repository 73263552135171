import { useNavigate } from "react-router-dom";

const CardAction = ({
  index,
  item,
  search,
  reset,
  isAtivo,
  isCardOptionsVisible,
  setCardOptionsIndex,
  onAtivar,
  onInativar,
  onExcluir,
  showExcluir,
  editarLink,
  editarEvent,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-end relative">
        <button
          type="button"
          onClick={() => setCardOptionsIndex(isCardOptionsVisible ? -1 : index)}
          onBlur={() => setCardOptionsIndex(-1)}
          className="acoes w-[40px] h-[40px] bg-[#F4F4F4] rounded-[50%] flex flex-col items-center cursor-pointer"
        >
          <div
            className={`w-auto top-[100%] h-auto mt-2 absolute rounded-lg bg-white shadow-lg z-10 ring-1 ring-black ring-opacity-5 overflow-y-auto ${
              isCardOptionsVisible ? "" : "hidden"
            }`}
          >
            <ul className="text-black cursor-pointer flex flex-col items-end">
              <li
                className="w-full flex justify-end text-[#676767] px-4 py-2 text-[13px] border-b hover:bg-slate-100"
                onClick={() => {
                  editarEvent?.();
                  editarLink && navigate(editarLink);
                  setCardOptionsIndex(-1);
                }}
              >
                Editar
              </li>

              {onInativar && onAtivar && (
                <li
                  className="w-full flex justify-end text-[#676767] px-4 py-2 text-[13px] border-b hover:bg-slate-100"
                  onClick={() => {
                    setCardOptionsIndex(-1);
                    isAtivo
                      ? onInativar?.(item, search)
                      : onAtivar?.(item, search);
                  }}
                >
                  {isAtivo ? "Inativar" : "Ativar"}
                </li>
              )}

              {showExcluir && (
                <li
                  className="w-full flex justify-end text-[#676767] px-4 py-2 text-[13px] hover:bg-slate-100"
                  onClick={() => {
                    setCardOptionsIndex(-1);
                    onExcluir?.(item, search, reset);
                  }}
                >
                  Excluir
                </li>
              )}
            </ul>
          </div>
        </button>
      </div>
    </>
  );
};

export default CardAction;
