import { useId, useState } from "react";
import { useTranslation } from "react-i18next";

import OlhoAberto from "./../../assets/svg/olho-aberto.svg";
import OlhoFechado from "./../../assets/svg/olho-fechado.svg";

const InputInline = ({
  label,
  name,
  type = "text",
  placeholder,
  value,
  register,
  validate,
  errors,
  readonly,
  onChange,
  onFocus,
  onBlur,
  required,
  icon,
  button,
  inputRef,
  className,
  maxLength,
  disabled
}) => {
  const id = useId();
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      {label && (
        <label htmlFor={id} className="text-[#8A92A6] text-[12px] pt-1">
          {label}
        </label>
      )}
      <div className="relative w-full">
        <input
          id={id}
          ref={inputRef}
          readOnly={readonly || disabled}
          onChange={() => onChange?.()}
          onFocus={() => onFocus?.()}
          onBlur={() => onBlur?.()}
          {...register?.(name, {
            validate: validate,
            value: value,
            onChange: onChange,
            required: required && "Este campo não pode ficar vazio",
          })}
          value={!register ? value : undefined}
          className={`w-full border border-[#686868] rounded-md pl-2 m-f1:py-1 m-f2:py-2 d-f1:py-3 text-[12px] outline-none
          ${className}
          ${icon && "pl-10"} 
          ${(readonly || disabled) && "bg-[#ece6e6] border-[#ece6e6]"}
          ${errors?.[name] && "!border-[#AF0505]"}`}
          type={isVisible ? "text" : type}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        {icon && (
          <img
            className="absolute top-1/2 left-2 -translate-y-1/2"
            alt="Icone de Nome"
            src={icon}
          />
        )}
        {button}
        {type === "password" && (
          <button
            type="button"
            onClick={() => !readonly && setIsVisible(!isVisible)}
            className="absolute bottom-0 right-0 h-full px-2 w-11 flex items-center justify-center bg-[#1D284A] rounded-r"
          >
            <img
              alt="Exibir senha"
              title="Exibir senha"
              src={isVisible ? OlhoAberto : OlhoFechado}
            />
          </button>
        )}
      </div>
      <div className="flex justify-start">
        {errors?.[name] && (
          <span className="text-[#AF0505] text-[12px] my-[3px]">
            {errors?.[name].message}
          </span>
        )}
      </div>
    </div>
  );
};

export default InputInline;
