import { Outlet, Navigate } from "react-router-dom";
import useRequireLogin from "../../hooks/useRequireLogin";
import Header from "./Header";
import MenuLeft from "./MenuLeft";

const Layout = () => {
  const { isLoading, redirectPath } = useRequireLogin();

  if (isLoading) {
    return <></>;
  }

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <div id="background-geral">
      <Header />
      <MenuLeft />
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
